<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import router from '@/router/index'
import {  mapState } from "vuex";
/**
 * Login component
 */
export default {
  page: {
    title: "Iniciar sesión",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      /*email: "desarrollo.w36@gmail.com",
      password: "qwerty.123",/**/ 
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    
  ...mapState('auth', ['currentUser']),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    retirectTO(){
             /* */
             if(this.currentUser.role===1){
                  router.push({ name: 'reportes' })
             }
             if(this.currentUser.role===3){
                  router.push({ name: 'intereses' })
             }
    },
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        //if (true) {
          this.tryingToLogIn = true;
          // Reset the authError if it existed.
          this.authError = null;
          return (
            this.$store
              .dispatch("auth/logIn", {
                email: this.email,
                password: this.password,
              })
              
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                console.log('token', token);
                this.tryingToLogIn = false;
                this.isAuthError = false;
                this.retirectTO();
                // Redirect to the originally requested page, or to the home page
                 


                
              })
              .catch((error) => {
                this.tryingToLogIn = false;
                this.authError = error ? error : "";
                this.isAuthError = true;
              })
          );
        /*} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          const { email, password } = this;
          if (email && password) {
            this.$store.dispatch("authfack/login", {
              email,
              password,
            });
          }
        }*/
      }
    },
  },
};
</script>

<template>
  <div>
    <!--<div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>-->
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo.svg"
                  alt
                  height="50"
                  class="logo logo-dark"
                />
                <img
                  src="@/assets/images/logo.svg"
                  alt
                  height="22"
                  class="logo logo-light"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="head-text">Bienvenido</h5>
                  <p class="text-muted">Ingresar al sistema</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div
                    v-if="notification.message"
                    :class="'alert ' + notification.type"
                  >
                    {{ notification.message }}
                  </div>

                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Correo Electrónico"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="email"
                        type="text"
                        placeholder="Ingresar Correo Electrónico"
                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.email.required"
                          >Correo Electrónico is requerido.</span
                        >
                        <span v-if="!$v.email.email"
                          >Por favor ingresa un Correo Electrónico valido.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <div class="float-end">
                        <router-link
                          to="/account/forgot-password"
                          class="text-muted"
                          >¿Olvidaste tu contraseña?</router-link
                        >
                      </div>
                      <label for="input-2">Contraseña</label>
                      <b-form-input
                        id="input-2"
                        v-model="password"
                        type="password"
                        placeholder="Ingresar contraseña"
                        :class="{
                          'is-invalid': submitted && $v.password.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && !$v.password.required"
                        class="invalid-feedback"
                      >
                        Contraseña requerida.
                      </div>
                    </b-form-group>
                   
                    <div class="mt-3 text-end">
                      <b-button type="submit" variant="primary" class="w-sm btn-login"
                        >Enviar</b-button
                      >
                    </div>

                
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <p>
                © {{ new Date().getFullYear() }} Agencia DigitalMA
              </p>
            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" module></style>
